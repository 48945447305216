import {Environment} from './type';

export const environment: Environment = {
  sentry_dsn: {
    management: 'https://064383fd91e59d13ff1e758fb5f19ad8@sentry.infra.paperless.io/3',
    submission: 'https://be2f18649f8314e2338f07b58bf7b252@sentry.infra.paperless.io/4',
    render: 'https://95a65227daafed30e9f2fdb1f00e2581@sentry.infra.paperless.io/5'
  },
  stripe_publishable_key: '',
  release: 'bbf0b00f8631ad8217878ffdda4d5516502ae2e1',
  name: 'production',
  production: true,
  api: {
    core: '/api/',
    iban: '/iban/'
  },
  rendering_api_key: 'ahties0Ga0jaig8eX7dai6cahzookie6ezeetha2aisie1ku0nuwae7Zaiwengie2Aix0qua3iecae4liekie8na4luu3Eingunee1eemorah2AiChah3eeDoo6eeshu',
  signmeBaseUrl: 'https://cloud.sign-me.de',
  deployUrl: 'https://app.paperless.io',
  websocket: {
    url: 'wss://app.paperless.io/cable',
    channel: {
      bucketChannel: 'API::V1::BucketChannel',
      processRunsChannel: 'API::V1::Streaming::ProcessRunsChannel'
    }
  },
  marketplaceBaseUrl: '/cms',
  marketplaceToken: 'dMF6gAVqbw7e05dgjSN__WXbdblczeXW',
  marketplaceContactSupportWebhookUUID: '9a3849f2-d827-42b4-9f34-c683f4f15d8f',
  validationTemplateUsageFlowId: 'e221f456-e2cc-4e8e-8d5b-ea8da526bda7',
  gtm: {
    id: 'GTM-5TPXFQ2',
    auth: 'WCJ8HK7fEbGhahz89AdMPw',
    env: 'env-1',
    resource_path: 'https://gtm.paperless.io/gtm.js'
  },
  intercom_app_id: 'rccoaglb'
};
